.row{
    width: 100%;
    padding: 5px 50px;
}
.text{
    margin-bottom: 0.5rem!important;
    display: flex;
}
input{
    height: 30px!important;
}
select{
    height: 36px!important;
}
.name_param{
    width: 19vw;
    margin: auto 0;
}
.zones input{
    width: 10vw;
    margin: 5px auto;
}
.zones{
    display: flex;
}
.name_zones{
    margin: 0 auto;
}
.header{
    /* border: 1px solid gray; */
    border-radius: 2px;
    background-color: #c0c0c0;
    padding: 2px;
}
.furnace{
    margin: 5px 0;
}
h4{
    text-align: center;
}
button{
    margin: 5px!important;
}
.culc{
    margin-left: auto;
}
#pyat::-webkit-inner-spin-button {
    opacity: 1;
  }


