img{
    border-radius: 2px;
    margin: 5px;
    /* width: 4vw;
    height: 4vw;
    min-width: 50px;
    min-height: 50px; */
}
.navbar-brand{
    cursor: pointer;
}
.row{
    width: 100%;
    padding: 5px 50px;
}
.text{
    margin-bottom: 0.5rem!important;
    display: -webkit-flex;
    display: flex;
}
input{
    height: 30px!important;
}
select{
    height: 36px!important;
}
.name_param{
    width: 19vw;
    margin: auto 0;
}
.zones input{
    width: 10vw;
    margin: 5px auto;
}
.zones{
    display: -webkit-flex;
    display: flex;
}
.name_zones{
    margin: 0 auto;
}
.header{
    /* border: 1px solid gray; */
    border-radius: 2px;
    background-color: #c0c0c0;
    padding: 2px;
}
.furnace{
    margin: 5px 0;
}
h4{
    text-align: center;
}
button{
    margin: 5px!important;
}
.culc{
    margin-left: auto;
}
#pyat::-webkit-inner-spin-button {
    opacity: 1;
  }



.about_header{
    margin: 10px 0;
}
.additional_parameters{
    margin: 10px 0;
}
.modeling{
    width: 100%;
    padding: 5px 50px;
}
fieldset{
    border: 1px solid black!important;
    padding: 5px!important;
}
legend{
    width: -webkit-fit-content!important;
    width: -moz-fit-content!important;
    width: fit-content!important;
    padding: 0 10px!important;
}
.selectMode{
    display: -webkit-flex;
    display: flex;
}
.radioButton{
    height: auto!important;
    margin: 0 5px;
}
.table_temperature{
    margin: 20px 0;
}
