.modeling{
    width: 100%;
    padding: 5px 50px;
}
fieldset{
    border: 1px solid black!important;
    padding: 5px!important;
}
legend{
    width: fit-content!important;
    padding: 0 10px!important;
}
.selectMode{
    display: flex;
}
.radioButton{
    height: auto!important;
    margin: 0 5px;
}
.table_temperature{
    margin: 20px 0;
}