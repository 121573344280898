img{
    border-radius: 2px;
    margin: 5px;
    /* width: 4vw;
    height: 4vw;
    min-width: 50px;
    min-height: 50px; */
}
.navbar-brand{
    cursor: pointer;
}